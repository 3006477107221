@import '../../../../styles/fonts';
@import '../../highlight/styles/desktop';
@import './card-icon-desktop';
@import './card-item-desktop';
@import './card-media-desktop';
@import './card-grid-media-desktop';
@import './card-poster-desktop';

$slider-spacing: 16;
$slider-per-view-5: 5;
$slider-per-view-6: 6;
$container-percentage-for-slider-5: calc(100 / $slider-per-view-5);
$container-percentage-for-slider-6: calc(100 / $slider-per-view-6);
$space-between-sliders-5: calc(
  $slider-spacing - ($slider-spacing / $slider-per-view-5)
);
$space-between-sliders-6: calc(
  $slider-spacing - ($slider-spacing / $slider-per-view-6)
);

.dynamic-access {
  .container {
    z-index: 4;
    margin-top: 300px;

    @media (width >= 1600px) {
      margin-top: 360px;
    }

    .andes-carousel-snapped__container--content.andes-carousel-snapped__container--with-controls.andes-carousel-snapped__container--strict-boundaries {
      margin-left: 0;
      width: 100%;
    }

    .andes-carousel-snapped__slide {
      width: calc(
        #{$container-percentage-for-slider-5}#{'%'} - #{$space-between-sliders-5}px
      ) !important;
    }

    @media (width >= 1180px) {
      .andes-carousel-snapped__slide {
        width: calc(
          #{$container-percentage-for-slider-6}#{'%'} - #{$space-between-sliders-6}px
        ) !important;
      }
    }
  }
}

.dynamic-access-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1px;
  border-radius: var(--dynamic-access-card-border-radius, $border-radius-6);
  background: $andes-white;
  cursor: pointer;

  @media (min-width: 1180px) {
    width: 183px;
  }

  &:hover {
    .dynamic-access-card-ecosistemic-footer,
    .dynamic-access-card-item__buy-now {
      background-color: $andes-blue-200;
      border-color: transparent;
      color: $andes-blue-500;
    }
    .dynamic-access-card-item__item-title,
    .description-content__title-name {
      color: $andes-blue-500;
    }
    .dynamic-access-card-ecosistemic__description {
      color: $andes-blue-500;
    }
  }

  &__small {
    height: 285px;
    .dynamic-access-card-ecosistemic-footer {
      margin: 16px;
    }
    .dynamic-access-card-item__buy-now--with-image,
    .dynamic-access-card-item__buy-now {
      margin: 0 16px 16px;
    }
    .dynamic-access-card-item__item-description--with-actions {
      margin: 15px 16px 5px;
    }
    .dynamic-access-card-ecosistemic-description {
      height: 48px;
    }
  }

  &__medium {
    height: 300px;
    &:has(.andes-money-amount__discount) {
      .dynamic-access-card-item__buy-now {
        margin: 0 16px 16px 16px;
      }
    }
    .dynamic-access-card-ecosistemic-description {
      height: 67px;
    }
    .dynamic-access-card-item__item-description--with-discount.dynamic-access-card-item__item-description--with-actions {
      margin: 12px 16px 2px;
    }
    .dynamic-access-card-item__item-description--without-discount {
      margin: 16px;
    }
    &:has(.andes-money-amount__discount) {
      .dynamic-access-card-item__buy-now {
        margin: 6px 16px 16px 16px;
      }
    }
  }

  &__long {
    height: 322px;
    .dynamic-access-card-ecosistemic-description {
      height: 100px;
    }
    .dynamic-access-card-item__buy-now {
      margin: 13px 16px 16px 16px;
    }
    .dynamic-access-card-item__item-description--with-actions {
      height: 92px;
    }
    .dynamic-access-card-item__item-description--with-discount.dynamic-access-card-item__item-description--with-actions {
      margin: 12px 16px 0px;
    }
    &:has(.andes-money-amount__discount) {
      .dynamic-access-card-item__item-description--with-actions {
        height: 100px;
      }
    }
    .dynamic-access-card-item__buy-now.dynamic-access-card-item__buy-now--with-image {
      margin: 38px 16px 16px 16px;
    }
  }

  &--with-border::after{
      content: '';
      position: absolute;
      inset: 0;
      border: solid var(--dynamic-access-card-border-width, 1px) $andes-blue-500;
      border-radius: var(--dynamic-access-card-border-radius, $border-radius-6);
      box-sizing: border-box;
  }

  &-border-color-green_primary::after {
    border-color: $andes-green-500;
  }

  &.dynamic-access-card-common{
    border-radius: var(--dynamic-access-card-border-radius, $border-radius-6);
    position: relative;
  }
}
